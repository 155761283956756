<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right;">
				<el-button type="infor" icon="el-icon-plus" size="small" @click="addNew()">添加配置项</el-button>

				<el-button type="infor" icon="el-icon-s-opportunity" size="small" @click="showBiaozhu()">企业标注定义</el-button>

			</div>
			<div style="padding-top: 14px;">

				<div style=" width: 200px;  margin-top:-10px;">
					<div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
						<img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
					</div>
					<div style="margin-left: 50px; color:#535353; font-size:20px !important">基础设置</div>
				</div>

			</div>

		</div>
		<div style="height: 85%;">



			<div style="font-size: 12px; padding: 20px;">
				<span style="color: orangered; font-weight: bolder;">【leftlogo】</span>首页左上角图标（200x50）；
				<span style="color: orangered; font-weight: bolder;">【schoolname】</span>学校名称；
				<span style="color: orangered; font-weight: bolder;">【favico】</span>浏览器tab页小图标；

			</div>
			<el-form :model="firstRow" ref="ruleForm" label-width="80px">
				<el-row v-for="(item, idx) in DataList" :key="idx">
					<el-col :span="6">
						<el-form-item label="参数名称" prop="role_name">
							<el-input v-model.trim="item.remark" style="width: 100%" />
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="参数代码" prop="role_name">
							<el-input v-model.trim="item.ktype" style="width: 100%" />
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="参数值" prop="role_name">
							<el-input v-model.trim="item.kvalue" style="width: 100%" />
						</el-form-item>
					</el-col>
					<el-col :span="6" class="flexStart">
						<el-form-item>

							<el-upload style="display: inline-block; margin-right: 20px;" action=""
								:http-request="uploadOss" :on-success="uploadFiles" :show-file-list="false" name="image">
								<el-button title="传图" size="small" type="primary" icon="el-icon-picture-outline-round"
									@click="setRowIndx(idx)" circle=""></el-button>
							</el-upload>


							<el-button size="small" type="primary" icon="el-icon-s-claim" @click="saveData(item)">保存
							</el-button>

						</el-form-item>
						<div v-if="item.isimg" class="thumbnail flexCenter"
							style="width:100px;height: 50px;margin-bottom: 22px;border: 1px dashed #909399;margin-left: 20px;cursor: pointer;">
							<img :src="item.kvalue" alt="" style="max-width:100%;max-height: 100%;"
								@click="viewImage(item.kvalue)">
						</div>
					</el-col>
				</el-row>

			</el-form>
		</div>


		<el-dialog title="详情" width="600px" :visible.sync="dialogImg" @close="dialogImg = false">
			<div class="flexCenter" style="width: 100%;"><img :src="viewImgUrl" alt="" style="max-width: 100%;"></div>

		</el-dialog>

		<biaozhu v-if="dialogShow"></biaozhu>

		

	</div>
</template>

<script>
import biaozhu from '../pages/com/biaozhu.vue'
export default {
	name: 'stuDept',
	props: ["pmid"],
	components:{biaozhu},
	data() {
		return {
			dialogShow: false,
			DataList: [],
			firstRow: {
				id: 0,
				pmid: 0,
				ktype: "",
				kvalue: "",
				remark: ""

			},
			rowidx: -1,
			target_pmid: 10,
			dialogImg: false,
			viewImgUrl: ''
		}


	},


	mounted() {
		this.getList()
	},
	methods: {
		closeDialog() {
			this.$parent.dialogPramShow = false
		},
		getList() {
			this.$http.post("/api/sys_sch_config_list", {
				pagesize: 50
			}).then(res => {
				res.data.data && res.data.data.map(a => {
					if (a.kvalue.indexOf('oss') > -1) {
						a.isimg = true
					}
				})
				this.DataList = res.data.data
			})
		},

		addNew() {
			this.DataList.push({
				...(this.firstRow)
			})
		},
		setRowIndx(idx) {
			this.rowidx = idx
		},
		uploadFiles(e) {
			console.log(e)
			console.log(this.rowidx)
			this.DataList[this.rowidx].kvalue = e.src
		},
		saveData(item) {
			item.pmid = this.pmid;
			this.$http.post("/api/sys_sch_config_edit", item).then(ok => {
				this.$message.success('保存成功')

				this.getList()
			})
		},
		initMenu() {

			this.$confirm(`此操作将删除老的配置，并复制对应学校的配置，包括菜单、角色、小程序配置。
				初始化之前请到sysplatform表里把默认管理员设置好，当前拷贝学校id为:${this.target_pmid},确认初始化?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				this.$http.post("/api/platform_init", {
					target_pmid: this.target_pmid,
					force: 1
				}).then(ok => {
					this.$message.success('初始化成功')
					this.getList()
				})

			}).catch(() => {

			});


		},
		initNotify() {
			this.$confirm(`此操作将删除老的配置，并复制对应学校的配置，包括菜单、角色、当前拷贝学校id为:${this.target_pmid},确认初始化?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				this.$http.post("/api/platform_module_init", {
					target_pmid: this.target_pmid,
					module: "notify"
				}).then(ok => {
					this.$message.success('初始化成功')

				})

			}).catch(() => {

			});

		},
		viewImage(url) {
			this.dialogImg = true
			this.viewImgUrl = url
		},
		showBiaozhu() {
			this.dialogShow = true
		}
	}
}
</script>

<style></style>
