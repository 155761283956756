<template>
    <div>
        <el-dialog class="cus_dialog " title="企业标注定义" :visible.sync="dialogshow" :close-on-click-modal="false"
            @close="closeDialog" width="500px" height="90%">
            <div style="text-align: right; padding-bottom: 10px;">
                <el-button type="infor" icon="el-icon-plus" size="small" @click="add()">添加标注</el-button>

            </div>
            <el-table :data="DataList" row-key="id" border stripe>
                <el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
                <el-table-column label="标注内容" prop="bz_name" min-width="100" align="center"></el-table-column>

                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button  @click="deleteRow(scope.row)" type="text"
                            size="small">删除</el-button>
                        <el-button @click="viewRow(scope.row)" type="text" size="small">编辑</el-button>
                    </template>
                </el-table-column>

            </el-table>

        </el-dialog>


        <el-dialog class="cus_dialog " title="标注修改" :visible.sync="dialogshow2" :close-on-click-modal="false"
            @close="closeDialog2" width="600px" height="90%">

            <table class="dttable">
                <tr>
                    <td class="tdheader">标注内容</td>
                    <td>
                        <el-input v-model="ckdItem.bz_name"></el-input>
                    </td>
                </tr>


            </table>

            <div style="text-align: right; padding-top:30px">
                <el-button icon="el-icon-s-claim" @click="saveInfo()" type="primary" size="small">保存</el-button>
            </div>



        </el-dialog>


    </div>
</template>

<script>

export default {
    props: ["xunfang"],
    components: {

    },
    data() {
        return {
            dialogshow: true,
            saveBtnShow: false,
            dialogshow2: false,
            DataList: [],
            ckdItem: {}
        }
    },
    mounted() {

        this.getList()
    },
    methods: {
        add() {
            this.ckdItem = { bz_name: "" }
            this.dialogshow2 = true
        },
        getList() {

            this.$http.post("/api/enter_biaozhu_list").then(res => {
                this.DataList = res.data
            })
        },
        closeDialog() {

            this.$$parent(this, "dialogShow", false)

        },
        deleteRow(e) {

            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post("/api/enter_biaozhu_delete", { id: e.id }).then(res => {
                    this.getList()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                   
                })
            }).catch(() => {

            });
        },
        viewRow(e) {

            this.ckdItem = e;
            this.dialogshow2 = true
        },
        closeDialog2() {

            this.dialogshow2 = false

        },
        saveInfo() {
            this.$http.post("/api/enter_biaozhu_edit", this.ckdItem).then(res => {
                this.closeDialog2()
                this.getList()
                this.$message({
                    type: 'success',
                    message: '保存成功!'
                });
               

            })
        }



    }

}

</script>

<style scoped>
.xiaojie {
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
</style>